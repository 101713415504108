import React from "react";
// import Pong from "react-pong";

import styles from "./index.module.scss";

const Game = () => {
  return (
    <section id="pong" className={styles.game}>
      <h2>Pong</h2>
      <div className={styles.gameInner}>{/* <Pong /> */}</div>
    </section>
  );
};

export default Game;
