import React, {
  PureComponent,
  Component,
  useState,
  useEffect,
  memo,
} from "react";
import Ticker from "react-ticker";
import styles from "./index.module.scss";
import data from "@utilities/data/data.js";
import useInterval from "@components/useInterval";
import moment from "moment-timezone";
import Countdown from "react-countdown";
import Select from "react-select";
import slugify from "slugify";
import AspectRatio from "react-aspect-ratio";
import StoreContext from "@context/store.js";
import selectStyles from "./select.js";
import { OutboundLink, trackCustomEvent } from "gatsby-plugin-google-analytics";

let hidden = null;
let visibilityChange = null;
if (typeof document != "undefined") {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }
}

class Performances extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      dayFilter: null, // Tues = 91
      fixed: false,
      liveUpdates: [],
      errors: 0,
      timerRun: true,
    };
    this.updateTimer = null;
    this.handleArtistChange = this.handleArtistChange.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleSpacer = this.handleSpacer.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.scrollWindowToSectionTop = this.scrollWindowToSectionTop.bind(this);
    this.updateShows = this.updateShows.bind(this);
    this.offset = this.offset.bind(this);
  }
  componentDidMount = () => {
    this.handleSpacer();
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleScroll);
    window.addEventListener("resize", this.handleSpacer);
    if (typeof document != "undefined") {
      document.addEventListener(
        visibilityChange,
        this.handleVisibilityChange,
        false
      );
    }
    this.startTimer();
  };
  componentWillUnmount() {
    if (typeof window == "undefined") {
      return;
    }
    this.stopTimer();
  }
  startTimer = () => {
    const { timerRun } = this.state;
    if (typeof window != "undefined" && this.updateTimer === null && timerRun) {
      this.updateTimer = setInterval(this.updateShows, 45 * 1000);
    }
  };
  stopTimer = () => {
    window.clearInterval(this.updateTimer);
    this.updateTimer = null;
  };
  handleVisibilityChange = () => {
    if (document[hidden]) {
      this.stopTimer();
    } else {
      this.startTimer();
    }
  };
  offset = (el) => {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  };

  updateShows = () => {
    const Http = new XMLHttpRequest();
    Http.open("GET", `/.netlify/functions/update`);
    Http.send();
    Http.onreadystatechange = ({ target }) => {
      const { results } = JSON.parse(target.responseText);
      this.setState({ liveUpdates: results });
    };
    Http.onerror = () => {
      const { errors } = this.state;
      if (errors >= 3) {
        this.stopTimer();
        this.setState({ timerRun: false });
        return;
      }
      this.setState({ errors: errors + 1 });
    };
  };
  scrollWindowToSectionTop = () => {
    const scrollToPos = this.offset(this.sectionRef).top;
    window.scrollTo({
      top: scrollToPos,
      left: 0,
      behavior: "smooth",
    });
  };
  handleArtistChange = (filters) => {
    let filtersTrimmed = [];
    filters &&
      filters.forEach((filter) => {
        filtersTrimmed.push(filter.value);
        trackCustomEvent({
          category: "homepage",
          action: `filtered_by_artist_${slugify(filter.label)}`,
          label: `Filter by Artist (${filter.label})`,
        });
      });
    this.setState({ filters: filtersTrimmed, dayFilter: null }, () => {
      this.scrollWindowToSectionTop();
    });
  };
  handleDayChange = (date) => {
    if (!date.value) {
      this.setState({ dayFilter: null });
      return;
    }
    this.setState({ dayFilter: date.value }, () => {
      this.scrollWindowToSectionTop();
      trackCustomEvent({
        category: "homepage",
        action: `filtered_by_date_${date.label}`,
        label: `Filter by Date ${date.label}`,
      });
    });
  };
  handleSpacer = () => {
    if (!this.filterRef || !this.filterInnerRef || !window) {
      return;
    }
    const height = this.filterInnerRef.offsetHeight;
    this.filterRef.style.minHeight = `${height}px`;
  };
  handleScroll = () => {
    if (!this.filterRef || !window) {
      return;
    }
    const headerHeight = 60;
    const { isInterior } = this.props;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const headerDistanceFromTop = this.offset(this.filterRef).top;
    const headerOffsetHeight = this.sectionRef.offsetHeight;
    if (scrollTop > headerDistanceFromTop - headerHeight + headerOffsetHeight) {
      // this.filterInnerRef.classList.remove(styles.datesFilterInnerFixed);
      this.setState({ fixed: false });
    } else if (
      scrollTop > headerDistanceFromTop - headerHeight &&
      !isInterior
    ) {
      this.filterInnerRef.classList.add(styles.datesFilterInnerFixed);
      this.setState({ fixed: true });
    } else {
      this.filterInnerRef.classList.remove(styles.datesFilterInnerFixed);
      this.setState({ fixed: false });
    }
  };
  render() {
    const { filters, dayFilter, fixed, liveUpdates } = this.state;
    const { shows } = this.props;
    // const { edges } = shows;

    moment.suppressDeprecationWarnings = true;

    const start = "2020-03-27";
    const end = "2020-05-31";

    let options = [];
    let dates = [
      {
        value: null,
        label: "View by Date",
      },
    ];
    for (
      var x = parseInt(moment().format("DDD"));
      x <= parseInt(moment(end).format("DDD"));
      x++
    ) {
      const delta = Math.abs(87 - x);
      const day = moment(start).add(delta, "days");
      dates.push({
        value: parseInt(day.format("DDD")),
        label: day.format("dddd L"),
      });
    }

    // edges.forEach((artist, index) => {
    //   const doesExist = options.filter((store) => {
    //     return artist.node.artist.toLowerCase() === store.label.toLowerCase();
    //   });
    //   if (doesExist.length > 0) {
    //     return;
    //   }
    //   options.push({
    //     value: slugify(artist.node.slug),
    //     label: artist.node.artist,
    //   });
    //   options.sort((a, b) =>
    //     a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    //   );
    // });

    let dateHeader = null;
    return (
      <StoreContext.Consumer>
        {(store) => {
          const { cartOpen } = store;
          return (
            <section
              id="shows"
              className={styles.dates}
              ref={(ele) => {
                this.sectionRef = ele;
              }}
            >
              <h2>Shows</h2>
              <div
                className={styles.datesFilter}
                ref={(ele) => {
                  this.filterRef = ele;
                }}
              >
                {/* <div
                  className={`${styles.datesFilterInner} ${
                    cartOpen && fixed ? styles.datesFilterInnerFixedOpen : ""
                  }`}
                  ref={(ele) => {
                    this.filterInnerRef = ele;
                  }}
                >
                  <div className={styles.datesFilterItem}>
                    <Select
                      isMulti
                      className={styles.datesFilterEl}
                      options={options}
                      label="Filter by Artist"
                      onChange={this.handleArtistChange}
                      styles={selectStyles}
                      placeholder="Filter by Artist"
                      noOptionsMessage={() => "No artists found..."}
                    />
                  </div>
                  <div className={styles.datesFilterItem}>
                    <Select
                      isSearchable={false}
                      className={styles.datesFilterEl}
                      options={dates}
                      label="Filter by Date"
                      onChange={this.handleDayChange}
                      styles={selectStyles}
                      value={dates.filter((date) => date.value === dayFilter)}
                      placeholder="Filter by Date"
                    />
                  </div>
                </div> */}
              </div>
              <div className={styles.datesEntries}>
                <h2>
                  Hey! We're over here{" "}
                  <a href="https://www.digitalpridefest.com">
                    Digital PrideFest
                  </a>
                </h2>
                {/* {edges &&
                  edges.map((show, index) => {
                    let skipHeader = false;
                    let liveData = null;
                    const {
                      node: { stageItId },
                    } = show;

                    // console.log("TEST", show.node.slug.replace("*", "")..replace("*", ""));
                    if (
                      filters.length > 0 &&
                      filters.indexOf(show.node.slug) === -1
                    ) {
                      return null;
                    }
                    if (dayFilter && show.node.day !== dayFilter) {
                      return null;
                    }
                    const dateObj = moment(show.node.date);
                    if (
                      dateHeader !== moment(show.node.date).format("dddd ll")
                    ) {
                      dateHeader = moment(show.node.date).format("dddd ll");
                    } else {
                      skipHeader = true;
                    }

                    const updatedShowData = liveUpdates.filter((show) => {
                      return show.stageItId == stageItId;
                    });
                    liveData =
                      updatedShowData.length > 0 && index <= 12
                        ? { node: updatedShowData[0] }
                        : show;

                    return <ShowComponent show={liveData} key={index} />;
                  })} */}
              </div>
            </section>
          );
        }}
      </StoreContext.Consumer>
    );
  }
}

const Show = ({ show }) => {
  let counter = 0;
  var buttonTimer = null;
  // var updateTimer = null;
  const { shows } = data;
  const showsWithImages = shows.filter((show) => {
    return !show.image ? false : true;
  });
  const {
    node: {
      title,
      artist,
      buttonCta,
      note,
      image,
      soldOut,
      count,
      date,
      day,
      url,
    },
  } = show;

  const getImageByArtist = (artistTitle) => {
    const filtered = showsWithImages.filter((show) => {
      return (
        show.artist.trim().toLowerCase() === artistTitle.trim().toLowerCase()
      );
    });

    return filtered.length === 1 ? filtered[0].image : null;
  };

  const imageFiltered = getImageByArtist(artist) || image;
  const dateClean = new Date(date).toString();
  const [buttonText, setButtonText] = useState(buttonCta);

  useEffect(() => {
    buttonTimer = setInterval(() => {
      if (!note) {
        return;
      }
      counter++;
      if (counter % 2 == 0) {
        setButtonText(note);
      } else {
        setButtonText(buttonCta);
      }
    }, 2000 + getRandomInt(1500));
    // updateTimer = setInterval(updateShow, 60 * 1000);
    return () => {
      if (typeof window != undefined) {
        window.clearInterval(buttonTimer);
      }
    };
  }, []);
  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  return (
    <div className={styles.datesEntry}>
      <div className={styles.datesEntryImage}>
        {imageFiltered && (
          <AspectRatio ratop={10 / 9}>
            {!soldOut ? (
              <>
                {/* <a href={`https://www.stageit.com${url}`} target="_blank"> */}
                <img
                  draggable={false}
                  alt={artist}
                  title={artist}
                  src={imageFiltered}
                  className={styles.datesEntryImageEl}
                  onClick={() => {
                    window.open(`https://www.stageit.com${url}`);
                    trackCustomEvent({
                      category: "homepage",
                      action: `click_artist_image_${url.replace(/\//g, "_")}`,
                      label: `Click Artist Image (${artist})`,
                    });
                  }}
                />
                {/* </a> */}
              </>
            ) : (
              <>
                <img
                  draggable={false}
                  alt={artist}
                  title={artist}
                  src={imageFiltered}
                  className={styles.datesEntryImageEl}
                />
              </>
            )}
          </AspectRatio>
        )}
        <AspectRatio ratop={10 / 9}>
          <img draggable={false} aria-hidden={true} src="/images/blank.png" />
        </AspectRatio>
      </div>
      <h4>{artist.replace("*", "")}</h4>
      <div className={styles.datesEntryTop}>
        <h3>
          {title.length > 28 ? (
            <Ticker
              direction={"toLeft"}
              mode={"await"}
              offset={"run-in"}
              speed={9}
            >
              {({ index }) => <>{title}</>}
            </Ticker>
          ) : (
            <span>{title}</span>
          )}
        </h3>
      </div>
      <div className={styles.datesEntryDate}>
        {moment
          .parseZone(dateClean)
          .tz("America/Los_Angeles")
          .format("ddd (M/D) - h:mma")}{" "}
        PDT /{" "}
        {moment.parseZone(dateClean).tz("America/New_York").format("h:mma")} EST
      </div>
      <div className={styles.datesEntryCountdown}>
        LIVE IN: <Countdown date={new Date(date)} />
      </div>
      {!soldOut && (
        <OutboundLink
          href={`https://www.stageit.com${url}`}
          className={styles.primaryCta}
          target="_blank"
          onClick={() => {
            trackCustomEvent({
              category: "homepage",
              action: `click_buy_ticket_button_${url.replace(/\//g, "_")}`,
              label: `Click Buy Ticket Button (${artist})`,
            });
          }}
        >
          {buttonText === buttonCta ? (
            <>
              {buttonText}
              {count && <span>{count} LEFT</span>}
            </>
          ) : (
            <>{buttonText}</>
          )}
        </OutboundLink>
      )}
      {soldOut && <span className={styles.datesEntrySoldout}>Sold Out</span>}
    </div>
  );
};

const ShowComponent = memo(Show);

export default Performances;
