import React from "react";
import data from "@utilities/data/data.js";
import moment from "moment";
import styles from "./index.module.scss";

const Press = () => {
  const { press } = data;
  return (
    <section id="press" className={styles.press}>
      <h2>Press</h2>
      <div className={styles.pressInner}>
        {press && (
          <ul>
            {press.map((item, index) => {
              return <PressItem data={item} key={index} />;
            })}
          </ul>
        )}
      </div>
    </section>
  );
};

const PressItem = ({ data }) => {
  return (
    <li>
      <a target="_blank" href={data.url}>
        <span>{data.publication}</span>
        <img src={data.image} />
        <h3>
          {data.headline}
          <time>{moment(data.date).format("L")}</time>
        </h3>
      </a>
    </li>
  );
};

export default Press;
