import React from "react";
import styles from "./index.module.scss";
import data from "@utilities/data/data.js";

const Sponsors = () => {
  const { sponsors } = data;
  return (
    <section id="sponsors" className={styles.sponsors}>
      <h2>Sponsors</h2>
      <div className={styles.sponsorsInner}>
        {sponsors &&
          sponsors.map((item, index) => {
            const { title, logo, url } = item;
            return (
              <div key={index} className={styles.sponsorsItem}>
                {logo ? (
                  <>
                    {url ? (
                      <a href={url} target="_blank">
                        <img draggable={false} title={title} src={logo} />
                      </a>
                    ) : (
                      <img draggable={false} title={title} src={logo} />
                    )}
                  </>
                ) : (
                  <h3>{title}</h3>
                )}
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default Sponsors;
