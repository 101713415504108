import React, { Component } from "react";
import { Link } from "gatsby";
import MagicGrid from "magic-grid-react";
import styles from "./index.module.scss";

class ProductGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //   articleDisplayCount: 0,
      //   articleDisplayCount: 4
    };
    this.handleImageLoad = this.handleImageLoad.bind(this);
  }
  loadMore() {
    // const { articleDisplayCount } = this.state;
    // this.setState({ articleDisplayCount: articleDisplayCount + 8 });
  }
  handleImageLoad() {
    setTimeout(() => {
      this.shopGrid.positionItems();
    }, 100);
  }
  render() {
    // var articlesDisplayed = 0;
    // let { articleDisplayCount } = this.state;
    let { products } = this.props;
    return (
      <section id="shop" className={styles.productsGrid}>
        <h2>Shop</h2>
        <ul className={styles.productsGridList}>
          <MagicGrid
            animate={true}
            ref={element => {
              this.shopGrid = element;
            }}
          >
            {products.map((product, idx) => {
              const { node } = product;
              const { title, variants, images } = node;
              //   const { fluid } = images[0].localFile.childImageSharp;
              const variantsOnSale = variants.filter(variant => {
                return variant.compareAtPrice !== null;
              });
              const onSale = variantsOnSale.length > 0;
              return (
                <li key={idx} className={styles.productsGridListItem}>
                  <Link to={`/shop/${product.node.handle}/`}>
                    <div>
                      <span className={styles.productsGridListItemPrice}>
                        $
                        {Math.round(
                          product.node.priceRange.minVariantPrice.amount
                        )}
                      </span>

                      <img
                        src={images[0].originalSrc}
                        alt={product.node.title}
                        title={product.node.title}
                        onLoad={this.handleImageLoad}
                      />
                    </div>
                    <h3>
                      <>
                        {title}
                        {onSale && (
                          <span className={styles.productsGridListItemSale}>
                            On Sale
                          </span>
                        )}
                      </>
                    </h3>
                  </Link>
                </li>
              );
            })}
          </MagicGrid>
        </ul>
      </section>
    );
  }
}

export default ProductGrid;
