import React, { useState } from "react";
import styles from "./index.module.scss";

const Instagram = () => {
  const defaultColor = { color: `#0600fc`, backgroundColor: `#ffffff` };
  const activeColor = { backgroundColor: `#0600fc`, color: `#ffffff` };
  const [background, setBackground] = useState(defaultColor);
  return (
    <section id="follow" className={styles.follow} style={background}>
      <h2>Follow Us</h2>
      <div className={styles.followInner}>
        <a
          target="_blank"
          onMouseEnter={() => {
            setBackground(activeColor);
          }}
          onMouseLeave={() => {
            setBackground(defaultColor);
          }}
          href="https://www.instagram.com/digitaldragfest"
        >
          @digitaldragfest
        </a>
      </div>
    </section>
  );
};

export default Instagram;
