import React, { useEffect, useState, memo } from "react";
import ReactPlayer from "react-player";
import ModalVideo from "react-modal-video";
import { Link } from "gatsby";
import { get } from "lodash";
import useWindowSize from "@utilities/window-size.js";

import styles from "./index.module.scss";
import "./../../../node_modules/react-modal-video/scss/modal-video.scss";

const HeaderVideo = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const windowSize = useWindowSize();

  return (
    <div className={`${styles.headerVideo}`}>
      <div className={`${styles.headerVideoDesktop}`}>
        <h2>
          <Link to="/shop">
            HEY QUEEN, BUY EXCLUSIVE MERCH FROM YOUR FAV QUEENS & SUPPORT GLAAD
          </Link>
        </h2>
        <ReactPlayer
          url={`/video/desktop-new.mp4`}
          width="100%"
          height="100%"
          playing={true}
          loop={true}
          muted={true}
          playsinline={true}
          config={{
            file: { attributes: { poster: "/images/header-still.jpg" } },
          }}
        />
      </div>
      <div className={`${styles.headerVideoMobile}`}>
        <h2>
          <Link to="/shop">
            HEY QUEEN, BUY EXCLUSIVE MERCH FROM YOUR FAV QUEENS & SUPPORT GLAAD
          </Link>
        </h2>
        <ReactPlayer
          url={`/video/mobile-new.mp4`}
          width="100%"
          height="100%"
          playing={true}
          loop={true}
          muted={true}
          playsinline={true}
          config={{
            file: { attributes: { poster: "/images/header-still-mobile.jpg" } },
          }}
        />
      </div>
      <div className={styles.headerVideoCtas}>
        <h3>
          <span>
            March 27 - May 31 <strong>Extended!</strong>
          </span>
        </h3>
        <button onClick={() => setModalOpen(true)}>Watch Trailer</button>
      </div>
      <ModalVideo
        channel="youtube"
        rel={0}
        autoplay={1}
        showInfo={0}
        isOpen={modalOpen}
        videoId={"5uIZ_umvNdU"}
        onClose={() => setModalOpen(false)}
      />
    </div>
  );
};

export default memo(HeaderVideo);
