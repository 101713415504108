import React from "react";
import styles from "./index.module.scss";

const About = () => {
  return (
    <section id="about" className={styles.about}>
      <h2>About</h2>
      <div className={styles.aboutInner}>
        <p>
          With home quarantine a reality for many and live events upended around
          the world, many of the biggest names in drag are coming together for a
          new digital concert festival. In partnership with management company
          Producer Entertainment Group (PEG) and digital concert service
          Stageit.com, “PEG Presents: Digital Drag Fest 2020” will feature
          Alaska, Alexis Michelle, BenDeLaCreme, Divina De Campo, Ginger Minj,
          Jackie Beat, Jiggly Caliente, Jinkx Monsoon, Jujubee, Manila Luzon,
          Miz Cracker, Monét X Change, Mrs. Kasha Davis, Nina West, Peppermint,
          Sharon Needles, Sherry Vine, Tammie Brown and Trinity The Tuck as they
          bring their legendary drag shows directly into the homes of fans.
          Additionally, PEG will feature a standalone concert from out country
          crooner Brandon Stansell, recently dubbed by Rolling Stone Country as
          an “artist you need to know.”
        </p>
        <p>
          Each 30-minute show will be a once in a lifetime live experience,
          which will never be recorded or re-released. Singing, cooking, comedy,
          lip syncing and drama are just some of the elements that will be
          showcased in this festival. Tickets will be extremely limited, to keep
          audience sizes small and the experience intimate, with opportunities
          to interact with artists, tip them, and potentially be awarded prizes
          and giveaways. Ticket prices for each show will start at an economical
          $10 USD per household. Shows will be suitable for all ages, though
          parental discretion is advised.
        </p>
        <p>
          “Drag is about resilience, and this festival is meant to share that
          message during a challenging time in our world,” said David
          Charpentier, Founder and President, PEG. “We want to give fans an
          opportunity to continue supporting their favorite queens and provide a
          welcome distraction for drag lovers around the globe,” he continued.
        </p>
        <p>
          About the festival, Jinkx Monsoon said: “Art endures all disasters;
          with art, so can we.”
        </p>
        <p>
          Trinity the Tuck added: “With all that’s going on in the world, we
          need an escape. Drag gives people some mental, stress and comic relief
          from the circumstances we face right now. I’m excited to share my art
          and hopefully make someone smile!”
        </p>
        <p>
          Manila Luzon said: “This is a chance to see your favorite drag queens
          in a totally new context where their own living rooms are their world
          stage!”
        </p>
        <p>
          "I think our users are in for a treat and I hope many of them will
          consider attending a Digital Drag Fest show," says Evan Lowenstein,
          Founder & CEO of Stageit. "We have no interest in replacing physical
          live events, but hopefully our service can help turn people on... to a
          completely new experience."
        </p>
      </div>
    </section>
  );
};

export default About;
