import React from "react";
import styles from "./index.module.scss";

const Video = () => {
  return (
    <section id="video" className={styles.about}>
      <h2>Video</h2>
      <div className={styles.aboutInner}>
        <div className={styles.aboutVideoWrap}>
          <iframe
            width="920"
            height="540"
            src="https://www.youtube.com/embed/5Ek_puy9-y4?modestbranding=1"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
      </div>
    </section>
  );
};

export default Video;
